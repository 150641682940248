import { CREATE_COMPANY, RETRIEVE_COMPANY, DELETE_COMPANY } from "./types";

import CompanyDataService from "../services/company.service";

export const createCompany =
	(
		id,
		name,
		address,
		phone,
		fax,
		email,
		company_no,
		vat_no,
		financial_start,
		financial_end,
		last_financial_start,
		last_financial_end,
		active
	) =>
	async (dispatch) => {
		try {
			if (active === "true") {
				active = true;
			}
			if (active === "false") {
				active = false;
			}

			let formData = {
				id: id,
				name: name,
				address: address,
				phone: phone,
				fax: fax,
				email: email,
				company_no: company_no,
				vat_no: vat_no,
				financial_start: financial_start,
				financial_end: financial_end,
				last_financial_start: last_financial_start,
				last_financial_end: last_financial_end,
				active: active,
			};

			const res = await CompanyDataService.create(formData);

			dispatch({
				type: CREATE_COMPANY,
				payload: res.data,
			});

			return Promise.resolve(res.data);
		} catch (err) {
			return Promise.reject(err);
		}
	};

export const retrieveCompany = (params) => async (dispatch) => {
	try {
		const res = await CompanyDataService.getAll(params);

		dispatch({
			type: RETRIEVE_COMPANY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};

export const deleteCompany = (id) => async (dispatch) => {
	try {
		const res = await CompanyDataService.delete(id);

		dispatch({
			type: DELETE_COMPANY,
			payload: res.data,
		});
	} catch (err) {
		return Promise.reject(err);
	}
};
