import http_fas from "../http-fas";

class CardDataService {
	getAll(params) {
		return http_fas.get("/cards", { params });
	}

	get(id) {
		return http_fas.get(`/cards/${id}`);
	}

	create(data) {
		return http_fas.post("/savecard", data);
	}

	delete(id) {
		return http_fas.delete(`/removecard/${id}`);
	}

	getType(type) {
		if (type === undefined) {
			type = "";
		}
		return http_fas.get(`master/card_type?tipe=` + type);
	}

	getCompanies() {
		return http_fas.get(`master/company`);
	}

	getDesignation() {
		return http_fas.get(`master/designation`);
	}
	getTaxes() {
		return http_fas.get(`master/tax`);
	}
	getCards(type) {
		if (type === undefined) {
			type = "";
		}
		return http_fas.get(`master/card?tipe=` + type);
	}
}

export default new CardDataService();
