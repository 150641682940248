import React, { Component } from "react";
import { connect } from "react-redux";
import { createOrder } from "../../actions/order";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import OrderDataService from "../../services/order.service";
import ReceiveMoneyDataService from "../../services/receiveMoney.service";
import JournalDataService from "../../services/journal.service";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";
import { getDueDate } from "../../helpers/date";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import { parseNumber } from "../../helpers/common";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import FormCard from "../card/popup";
import ClearIcon from "@mui/icons-material/Clear";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue } from "../../helpers/common";
import FormAccount from "../account/popup";

class AddOrder extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			tipe: "order",
			id_card: 0,
			code: "",
			code_po: "",
			date: format(new Date(), "yyyy-MM-dd"),
			due_date: getDueDate(),
			note: "",
			ship_to: "",
			id_payment_method: 0,
			tax_inclusive: false,
			detail: [],

			error: false,
			message: false,

			companies_ready: false,
			accounts_ready: false,
			paymentMethods_ready: false,
			cards_ready: false,
			jobs_ready: false,
			taxes_ready: false,

			companies: [],
			accounts: [],
			cards: [],
			jobs: [],
			taxes: [],
			paymentMethods: [],

			total_amount: 0,

			formCard: false,
			formAccount: false,
			activeRowAccount: false,
			activeRowAccountId: false,

			recurring: false,
			recurring_name: "",
		};
	}

	types = [
		{
			label: "Quote",
			value: "quote",
		},
		{
			label: "Order",
			value: "order",
		},
	];

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Order");
			if (typeof this.props.match.params.id !== "undefined") {
				let id = parseNumber(this.props.match.params.id);
				let id_recurring = parseNumber(this.props.match.params.id_recurring);

				if (id > 0) {
					this.loadCurrentRow();
				}

				if (id_recurring > 0) {
					this.loadRecurringRow(id_recurring);
				}
				this.loadSelect();
			} else {
				this.newData();
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadCards() {
		CardDataService.getCards(1).then((response) => {
			this.setState(
				{
					cards_ready: true,
					cards: response.data.Row,
				},
				function () {
					this.setState({
						formCard: false,
					});
				}
			);
		});
	}

	loadAccounts() {
		JournalDataService.getAccounts().then((response) => {
			this.setState(
				{
					accounts_ready: true,
					accounts: response.data.Row,
				},
				function () {
					if (this.state.activeRowAccountId > 0) {
						let detail = [...this.state.detail];
						detail[this.state.activeRowAccount]["id_account"] =
							this.state.activeRowAccountId;
						this.setState({
							detail,
							activeRowAccount: false,
							activeRowAccountId: false,
						});
					}

					this.setState({
						formAccount: false,
					});
				}
			);
		});
	}

	loadSelect() {
		this.loadCards();
		this.loadAccounts();

		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		JournalDataService.getJobs().then((response) => {
			this.setState({
				jobs_ready: true,
				jobs: response.data.Row,
			});
		});
		JournalDataService.getTaxes().then((response) => {
			this.setState(
				{
					taxes_ready: true,
					taxes: response.data.Row,
				},
				function () {
					this.calculateDetail();
				}
			);
		});
		ReceiveMoneyDataService.getPaymentMethod().then((response) => {
			this.setState({
				paymentMethods_ready: true,
				paymentMethods: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		OrderDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						tipe: response.data.Row.tipe,
						id_card: response.data.Row.id_card,
						code: response.data.Row.code,
						code_po: response.data.Row.code_po,
						date: response.data.Row.date,
						due_date: response.data.Row.due_date,
						note: response.data.Row.note,
						ship_to: response.data.Row.ship_to,
						id_payment_method: response.data.Row.id_payment_method,
						tax_inclusive: response.data.Row.tax_inclusive,
						detail: response.data.Row.Detail,
					},
					function () {
						this.props.hideLoading();
						this.calculateDetail();
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	loadRecurringRow(id_recurring) {
		this.props.showLoading();

		OrderDataService.code(this.props.auth.idCompany).then((resc) => {
			OrderDataService.get(id_recurring)
				.then((response) => {
					this.setState(
						{
							id: null,
							id_company: this.props.auth.idCompany,
							tipe: response.data.Row.tipe,
							id_card: response.data.Row.id_card,
							code: resc.data,
							code_po: "",
							date: format(new Date(), "yyyy-MM-dd"),
							due_date: getDueDate(),
							note: response.data.Row.note,
							ship_to: response.data.Row.ship_to,
							id_payment_method: response.data.Row.id_payment_method,
							tax_inclusive: response.data.Row.tax_inclusive,
							detail: response.data.Row.Detail,
						},
						function () {
							this.props.hideLoading();
							this.calculateDetail();
						}
					);
				})
				.catch((e) => {
					this.setState({ message: e.response.data.error });
				});
		});
	}

	newData() {
		OrderDataService.code(this.props.auth.idCompany).then((response) => {
			this.setState(
				{
					id: null,
					id_company: this.props.auth.idCompany,
					tipe: "order",
					id_card: 0,
					code: response.data,
					code_po: "",
					date: format(new Date(), "yyyy-MM-dd"),
					due_date: getDueDate(),
					note: "",
					ship_to: "",
					id_payment_method: 0,
					tax_inclusive: false,
					detail: [],

					message: false,
					total_amount: 0,
					recurring: false,
					recurring_name: "",
				},
				function () {
					this.props.hideLoading();
				}
			);
		});

		this.setState({});
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeTipe(e) {
		this.setState({ tipe: e.target.value });
	}
	onChangeIdCard(e) {
		let index = this.state.cards.map((item) => item.id).indexOf(e.target.value);

		this.setState({
			id_card: e.target.value,
			ship_to: this.state.cards[index]["name"],
		});
	}
	onChangeCode(e) {
		this.setState({ code: e.target.value });
	}
	onChangeCodePo(e) {
		this.setState({ code_po: e.target.value });
	}
	onChangeDate(e) {
		this.setState({ date: e.target.value });
	}
	onChangeDueDate(e) {
		this.setState({ due_date: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}
	onChangeShipTo(e) {
		this.setState({ ship_to: e.target.value });
	}
	onChangeIdPaymentMethod(e) {
		this.setState({ id_payment_method: e.target.value });
	}
	onChangeTaxInclusive(e) {
		this.setState({ tax_inclusive: e.target.checked }, function () {
			this.calculateDetail();
		});
	}
	onChangeRecurring(e) {
		this.setState({ recurring: e.target.checked });
	}
	onChangeRecurringName(e) {
		this.setState({ recurring_name: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveOrder(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		let detail = [...this.state.detail];

		for (let i = 0; i < detail.length; i++) {
			detail[i]["amount"] = parseNumber(detail[i]["amount"]);
			detail[i]["id_job"] = parseNumber(detail[i]["id_job"]);
			detail[i]["id_tax"] = parseNumber(detail[i]["id_tax"]);
		}

		this.setState({ detail }, function () {
			this.props
				.createOrder(
					this.state.id,
					this.state.id_company,
					this.state.tipe,
					this.state.id_card,
					this.state.code,
					this.state.code_po,
					this.state.date,
					this.state.due_date,
					this.state.note,
					this.state.ship_to,
					this.state.id_payment_method,
					this.state.tax_inclusive,
					this.state.detail,
					this.state.recurring,
					this.state.recurring_name
				)
				.then((data) => {
					this.newData();
					// this.props.history.push("/order/form");
				})
				.catch((e) => {
					this.setState({
						message: e.response.data.error,
						error: true,
					});
					this.props.hideLoading();
				});
		});
	}

	calculateDetail() {
		let ta = 0;
		let a = 0;
		let tax = 0;
		this.state.detail.forEach((d) => {
			if (!this.state.tax_inclusive) {
				if (d.id_tax > 0) {
					if (this.state.taxes_ready) {
						let index = this.state.taxes
							.map((item) => item.id)
							.indexOf(d.id_tax);
						tax = this.state.taxes[index]["field1"];
					} else tax = 0;
				} else {
					tax = 0;
				}
			} else {
				tax = 0;
			}

			a = parseNumber(d.amount);
			ta += a + a * (tax / 100);
		});
		this.setState({
			total_amount: ta,
		});
	}

	CustomPaper = (props) => {
		return <Paper {...props} sx={{ width: 450 }} />;
	};

	createUI() {
		return this.state.detail.map((e, i) => (
			<Grid key={i} container spacing={3}>
				<Grid item xs={12} sm={3}>
					<TextField
						id="memo"
						label="Description"
						value={e.memo}
						onChange={this.handleChange.bind(this, i)}
						fullWidth
						variant="standard"
					/>
				</Grid>

				<Grid item xs={12} sm={3}>
					<TextField
						sx={{ display: "none" }}
						type="hidden"
						id="id"
						value={e.id}
					/>

					<TextField
						sx={{ display: "none" }}
						type="hidden"
						id="id_sales"
						value={this.state.id}
					/>

					{this.state.accounts_ready && (
						<Grid container spacing={0}>
							<Grid item xs={2}>
								<IconButton
									onClick={(e) => this.addAccount(i)}
									color="primary"
									aria-label="add card"
									disabled={e.id > 0}
								>
									<AddBoxIcon />
								</IconButton>
							</Grid>
							<Grid item xs={10}>
								<Autocomplete
									id="id_account"
									name="id_account"
									fullWidth
									PaperComponent={this.CustomPaper}
									options={this.state.accounts}
									getOptionLabel={(option) => option.name}
									onChange={this.handleChange.bind(this, i)}
									value={AcIndexByValue(this.state.accounts, e.id_account)}
									readOnly={e.id > 0}
									renderInput={(params) => (
										<TextField variant="standard" {...params} label="Account" />
									)}
								/>
							</Grid>
						</Grid>
					)}
				</Grid>

				<Grid item xs={12} sm={2}>
					<FormControl
						fullWidth={true}
						variant="standard"
						sx={{ minWidth: 100 }}
					>
						<NumberFormat
							customInput={TextField}
							id="amount"
							label="Amount"
							value={e.amount}
							fullWidth
							variant="standard"
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							autoComplete="off"
							onBlur={this.handleChange.bind(this, i)}
							onKeyDown={this.handleEnter.bind(this, i)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={2}>
					{this.state.jobs_ready && (
						<FormControl
							fullWidth={true}
							variant="standard"
							sx={{ minWidth: 100 }}
						>
							<InputLabel id="id_job_label">Job</InputLabel>
							<Select
								id="id_job"
								name="id_job"
								labelId="id_job_label"
								label="Job"
								value={e.id_job}
								onChange={this.handleChange.bind(this, i)}
							>
								<MenuItem value="0">
									<em>None</em>
								</MenuItem>
								{this.state.jobs.map((option, index) => (
									<MenuItem key={index} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>

				<Grid item xs={12} sm={1}>
					{this.state.taxes_ready && (
						<FormControl
							fullWidth={true}
							variant="standard"
							sx={{ minWidth: 100 }}
						>
							<InputLabel id="id_tax_label">Tax</InputLabel>
							<Select
								id="id_tax"
								name="id_tax"
								labelId="id_tax_label"
								label="Tax"
								value={e.id_tax}
								onChange={this.handleChange.bind(this, i)}
							>
								<MenuItem value="0">
									<em>None</em>
								</MenuItem>
								{this.state.taxes.map((option, index) => (
									<MenuItem key={index} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</Grid>

				<Grid item xs={12} sm={1} container justifyContent="flex-end">
					<IconButton
						aria-label="Remove"
						onClick={this.removeClick.bind(this, i)}
					>
						<ClearIcon fontSize="small" />
					</IconButton>
				</Grid>

				<Grid item xs={12} sm={12}>
					<Divider sx={{ mb: 2 }} />
				</Grid>
			</Grid>
		));
	}

	addClick() {
		this.setState((prevState) => ({
			detail: [
				...prevState.detail,
				{
					id: 0,
					id_sales: this.state.id,
					id_account: 0,
					amount: 0,
					id_job: 0,
					memo: "",
					id_tax: 0,
				},
			],
		}));
	}

	handleChange(i, event, val) {
		let name;

		if (typeof event.target.id === "undefined") {
			name = event.target.name;
		} else {
			name = event.target.id;
		}

		if (name.includes("-")) {
			let tmp = name.split("-");
			name = tmp[0];
		}
		let detail = [...this.state.detail];

		if (name === "id_account") {
			if (val !== null) {
				detail[i][name] = val.id;
			}
		} else {
			detail[i][name] = event.target.value;
		}

		this.setState({ detail }, function () {
			this.calculateDetail();
		});
	}

	handleEnter(i, event) {
		if (event.key === "Enter") {
			let name;
			if (typeof event.target.id === "undefined") {
				name = event.target.name;
			} else {
				name = event.target.id;
			}

			let detail = [...this.state.detail];
			detail[i][name] = event.target.value;

			this.setState({ detail }, function () {
				this.calculateDetail();
			});
		}
	}

	removeClick(i) {
		let detail = [...this.state.detail];
		detail.splice(i, 1);
		this.setState({ detail }, function () {
			this.calculateDetail();
		});
	}

	addCard = () => {
		this.setState({ formCard: true });
	};

	formCardClose = () => {
		this.setState({ formCard: false });
	};

	setNewCard(id, name) {
		this.loadCards();
		this.setState({
			id_card: id,
			ship_to: name,
		});
	}

	addAccount(i) {
		this.setState({ activeRowAccount: i, formAccount: true });
	}

	formAccountClose = () => {
		this.setState({ formAccount: false });
	};

	setNewAccount(id) {
		this.setState({ activeRowAccountId: id }, function () {
			this.loadAccounts();
		});
	}

	showError(msg) {
		this.setState({
			message: msg,
			error: true,
		});
	}

	render() {
		return (
			<Box component="form" onSubmit={(e) => this.saveOrder(e)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
							<Typography variant="h5">Order Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>

									<FormCard
										show={this.state.formCard}
										cardType="1"
										formCardClose={(e) => this.formCardClose(e)}
										setNewCard={(id, name) => this.setNewCard(id, name)}
										showError={(e) => this.showError(e)}
									/>

									<FormAccount
										show={this.state.formAccount}
										formAccountClose={(e) => this.formAccountClose(e)}
										setNewAccount={(id, name) => this.setNewAccount(id, name)}
										showError={(e) => this.showError(e)}
										idCompany={this.props.auth.idCompany}
										idPeriod={this.props.auth.user.id_period}
									/>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={2}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.tax_inclusive === "true" ||
													this.state.tax_inclusive === true
												}
												onChange={(e) => this.onChangeTaxInclusive(e)}
											/>
										}
										label="Tax Inclusive"
									/>
								</Grid>

								<Grid item xs={12} sm={2}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="tipeLabel">Type</InputLabel>
										<Select
											labelId="tipeLabel"
											label="tipeLabel"
											value={this.state.tipe}
											onChange={(e) => this.onChangeTipe(e)}
										>
											{this.types.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={2}>
									<TextField
										label="Due Date"
										type="date"
										value={this.state.due_date}
										onChange={(e) => this.onChangeDueDate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<Grid container spacing={0}>
										<Grid item xs={2}>
											<IconButton
												onClick={this.addCard}
												color="primary"
												aria-label="add card"
											>
												<AddBoxIcon />
											</IconButton>
										</Grid>
										<Grid item xs={10}>
											{this.state.cards_ready && (
												<FormControl
													fullWidth={true}
													variant="standard"
													sx={{ minWidth: 100 }}
												>
													<InputLabel id="idCardsLabel">Card</InputLabel>
													<Select
														labelId="idCardsLabel"
														label="Card"
														value={this.state.id_card}
														onChange={(e) => this.onChangeIdCard(e)}
													>
														<MenuItem value="0">
															<em>None</em>
														</MenuItem>
														{this.state.cards.map((option, index) => (
															<MenuItem key={index} value={option.id}>
																{option.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Ship To"
										type="text"
										value={this.state.ship_to}
										onChange={(e) => this.onChangeShipTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Code"
										type="text"
										value={this.state.code}
										onChange={(e) => this.onChangeCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Date"
										type="date"
										value={this.state.date}
										onChange={(e) => this.onChangeDate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Code PO"
										type="text"
										value={this.state.code_po}
										onChange={(e) => this.onChangeCodePo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Note"
										fullWidth
										multiline
										rows={2}
										value={this.state.note}
										onChange={(e) => this.onChangeNote(e)}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.paymentMethods_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idPaymentMethodLabel">
												Payment Method
											</InputLabel>
											<Select
												labelId="idPaymentMethodLabel"
												label="Payment Method"
												value={this.state.id_payment_method}
												onChange={(e) => this.onChangeIdPaymentMethod(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.paymentMethods.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={12}>
									<Typography variant="h5">List Detail</Typography>
								</Grid>

								<Grid item xs={12} sm={12}>
									{this.createUI()}

									<Button
										variant="outlined"
										size="small"
										onClick={(e) => this.addClick(e)}
									>
										Insert Row
									</Button>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={7}></Grid>

								<Grid item xs={12} sm={5}>
									<TableContainer>
										<Table>
											<TableBody>
												<TableRow>
													<TableCell width={150}>Total Amount</TableCell>
													<TableCell width={1}>:</TableCell>
													<TableCell align="right">
														<NumberFormat
															value={this.state.total_amount}
															displayType={"text"}
															thousandSeparator="."
															decimalSeparator=","
															decimalScale={2}
															fixedDecimalScale={true}
															prefix={""}
														/>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>

								<Grid item xs={12} sm={12}>
									<FormControlLabel
										control={
											<Checkbox
												checked={this.state.recurring === true}
												onChange={(e) => this.onChangeRecurring(e)}
											/>
										}
										label="Save as Recurring"
									/>
									{this.state.recurring && (
										<TextField
											label="Recurring Name"
											value={this.state.recurring_name}
											onChange={(e) => this.onChangeRecurringName(e)}
											fullWidth
											variant="standard"
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/order/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createOrder,
	showLoading,
	hideLoading,
	setModule,
})(AddOrder);
