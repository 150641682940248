import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";
// import { linkType } from "../../helpers/common";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

class profitLossYTDReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateFrom, dateTo;

		if (this.props.common.dateFrom) {
			dateFrom = this.props.common.dateFrom;
		} else {
			dateFrom = format(new Date(), "yyyy-MM-01");
		}

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchFrom: dateFrom,
			searchTo: dateTo,
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			searchSort: "date",
			searchInclude0Balance: "0",
			rows: [],
			operatingProfit: [],
			netProfitLoss: [],
			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			xlsUrl: "https://fasreport.raywhite.co.id/profitlossytd",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Profit Loss Year to Date");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateFrom(this.state.searchFrom);
		this.props.setDateTo(this.state.searchTo);
		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			from: this.state.searchFrom,
			to: this.state.searchTo,
			include0balance: this.state.searchInclude0Balance.toString(),
		};

		ReportDataService.getProfitLossYTD(params)
			.then((response) => {
				this.setState({
					rows: response.data.Data,
					operatingProfit: response.data.operating_profit,
					netProfitLoss: response.data.net_profit_loss,
					message: false,
				});
				this.props.hideLoading();
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					error: true,
					message: e.response.data.error,
				});
				this.props.hideLoading();
			});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchIdCompany(e) {
		this.setState({ searchIdCompany: e.target.value });
	}
	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}
	onChangeSearchInclude0Balance(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchInclude0Balance: checked });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	rowClass(rows, tipe) {
		if (rows == null) {
			return;
		}

		if (rows.length === 0) {
			return;
		}

		let className = "";
		let totalSelectedPeriode = 0;
		let totalPercOfSales = 0;
		let totalYearToDate = 0;
		let totalPercOfYtdSales = 0;
		let j = 0;
		let iSelected = 0;

		for (let i = 0; i < rows.length; i++) {
			if (rows[i].id_classification === tipe) {
				className = rows[i].name;
				iSelected = i;

				for (j = 0; j < rows[i].Detail.length; j++) {
					totalSelectedPeriode += rows[i].Detail[j].selected_periode;
					totalPercOfSales += rows[i].Detail[j].perc_of_sales;
					totalYearToDate += rows[i].Detail[j].year_to_date;
					totalPercOfYtdSales += rows[i].Detail[j].perc_of_ytd_sales;
				}
			}
		}

		if (className === "") {
			return;
		}

		let s = (
			<TableRow>
				<TableCell sx={{ borderBottom: "none" }} colSpan={5}>
					<Typography variant="subtitle2" gutterBottom component="div">
						{className}
					</Typography>
				</TableCell>
			</TableRow>
		);

		let sss = (
			<TableRow>
				<TableCell>
					<Typography variant="subtitle2" gutterBottom component="div">
						Total {className}
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography variant="subtitle2" gutterBottom component="div">
						<NumberFormat
							value={totalSelectedPeriode}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography variant="subtitle2" gutterBottom component="div">
						<NumberFormat
							value={totalPercOfSales}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography variant="subtitle2" gutterBottom component="div">
						<NumberFormat
							value={totalYearToDate}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
				<TableCell align="right">
					<Typography variant="subtitle2" gutterBottom component="div">
						<NumberFormat
							value={totalPercOfYtdSales}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					</Typography>
				</TableCell>
			</TableRow>
		);

		let ss = rows[iSelected].Detail.map((row, i) => (
			<TableRow key={i}>
				<TableCell sx={{ borderBottom: "none" }}>{row.name}</TableCell>
				<TableCell sx={{ borderBottom: "none" }} align="right">
					<NumberFormat
						value={row.selected_periode}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
				<TableCell sx={{ borderBottom: "none" }} align="right">
					<NumberFormat
						value={row.perc_of_sales}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
				<TableCell sx={{ borderBottom: "none" }} align="right">
					<NumberFormat
						value={row.year_to_date}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
				<TableCell sx={{ borderBottom: "none" }} align="right">
					<NumberFormat
						value={row.perc_of_ytd_sales}
						displayType={"text"}
						thousandSeparator="."
						decimalSeparator=","
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={""}
					/>
				</TableCell>
			</TableRow>
		));

		return [s, ss, sss];
	}

	row2(row) {
		return (
			<>
				<TableRow colSpan={5}>
					<TableCell sx={{ borderBottom: "none" }}></TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<Typography variant="h6" gutterBottom component="div">
							{row.name}
						</Typography>
					</TableCell>
					<TableCell align="right">
						<Typography variant="h6" gutterBottom component="div">
							<NumberFormat
								value={row.selected_periode}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</Typography>
					</TableCell>
					<TableCell align="right">
						<Typography variant="h6" gutterBottom component="div">
							<NumberFormat
								value={row.perc_of_sales}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</Typography>
					</TableCell>
					<TableCell align="right">
						<Typography variant="h6" gutterBottom component="div">
							<NumberFormat
								value={row.year_to_date}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</Typography>
					</TableCell>
					<TableCell align="right">
						<Typography variant="h6" gutterBottom component="div">
							<NumberFormat
								value={row.perc_of_ytd_sales}
								displayType={"text"}
								thousandSeparator="."
								decimalSeparator=","
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={""}
							/>
						</Typography>
					</TableCell>
				</TableRow>
			</>
		);
	}

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.searchIdCompany}
													onChange={(e) => this.onChangeSearchIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchInclude0Balance === "1" ||
													this.state.searchInclude0Balance === 1
												}
												onChange={(e) => this.onChangeSearchInclude0Balance(e)}
											/>
										}
										label="Include 0 Balance"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									{this.state.companies_ready &&
										this.state.companies.length > 1 && (
											<FormControl>
												<FormLabel>Consolidate</FormLabel>
												<FormGroup>
													{this.state.companies.map((option, index) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																/>
															}
															value={option.id}
															label={option.name}
														/>
													))}
												</FormGroup>
											</FormControl>
										)}
								</Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&from=" +
											this.state.searchFrom +
											"&to=" +
											this.state.searchTo +
											"&include0balance=" +
											this.state.searchInclude0Balance +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Profit Loss Year to Date
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell align="center">Account</TableCell>
										<TableCell align="center">Selected Periode</TableCell>
										<TableCell align="center">% of Sales</TableCell>
										<TableCell align="center">Year to Date</TableCell>
										<TableCell align="center">% of YTD Sales</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.rows && this.state.rows.length > 0 && (
										<>
											{this.rowClass(this.state.rows, 4)}
											{this.rowClass(this.state.rows, 6)}

											{this.row2(this.state.operatingProfit)}

											{this.rowClass(this.state.rows, 8)}
											{this.rowClass(this.state.rows, 9)}

											{this.row2(this.state.netProfitLoss)}
										</>
									)}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(profitLossYTDReport);
