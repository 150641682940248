import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import NumberFormat from "react-number-format";
import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

class StatementOfCashFlowReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateFrom, dateTo;

		if (this.props.common.dateFrom) {
			dateFrom = this.props.common.dateFrom;
		} else {
			dateFrom = format(new Date(), "yyyy-MM-01");
		}

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			searchFrom: dateFrom,
			searchTo: dateTo,
			rows: [],
			rows_equity: [],
			net_income: 0,
			net_cash_flows_from_operating_activities: 0,
			net_cash_flows_from_financing_activities: 0,
			net_increase_decrease_for_the_period: 0,
			cash_at_the_beginning_of_the_period: 0,
			cash_at_the_end_of_the_period: 0,

			error: false,
			message: false,

			companies_ready: false,
			companies: [],

			xlsUrl: "https://fasreport.raywhite.co.id/statementofcashflow",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Statement of Cash Flow");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateFrom(this.state.searchFrom);
		this.props.setDateTo(this.state.searchTo);
		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			from: this.state.searchFrom,
			to: this.state.searchTo,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getStatementOfCashFlow(params)
				.then((response) => {
					this.setState({
						rows: response.data.data,
						rows_equity: response.data.data_equity,
						net_income: response.data.net_income,
						net_cash_flows_from_operating_activities:
							response.data.net_cash_flows_from_operating_activities,
						net_cash_flows_from_financing_activities:
							response.data.net_cash_flows_from_financing_activities,
						net_increase_decrease_for_the_period:
							response.data.net_increase_decrease_for_the_period,
						cash_at_the_beginning_of_the_period:
							response.data.cash_at_the_beginning_of_the_period,
						cash_at_the_end_of_the_period:
							response.data.cash_at_the_end_of_the_period,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		//if (this.props.auth.user.usergroup === "administrator") {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		//}
	}

	onChangeSearchIdCompany(e) {
		this.setState({ searchIdCompany: e.target.value });
	}
	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.searchIdCompany}
													onChange={(e) => this.onChangeSearchIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}></Grid>

								<Grid item xs={12} sm={12}>
									{this.state.companies_ready &&
										this.state.companies.length > 1 && (
											<FormControl>
												<FormLabel>Consolidate</FormLabel>
												<FormGroup>
													{this.state.companies.map((option, index) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																/>
															}
															value={option.id}
															label={option.name}
														/>
													))}
												</FormGroup>
											</FormControl>
										)}
								</Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&from=" +
											this.state.searchFrom +
											"&to=" +
											this.state.searchTo +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Statement of Cash Flow
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableBody>
									<TableRow key={0}>
										<TableCell>Cash Flow from Operating Activities</TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
									</TableRow>
									<TableRow key={1}>
										<TableCell colSpan={3}>&nbsp;</TableCell>
									</TableRow>
									<TableRow key={2}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Income
											</Typography>
										</TableCell>

										<TableCell align="right">
											<NumberFormat
												value={this.state.net_income}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
										<TableCell></TableCell>
									</TableRow>
									<TableRow key={3}>
										<TableCell colSpan={3}>&nbsp;</TableCell>
									</TableRow>

									{this.state.rows &&
										this.state.rows.map((row, index) => (
											<TableRow key={index + 4}>
												<TableCell>{row.name}</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.result}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={""}
													/>
												</TableCell>

												<TableCell></TableCell>
											</TableRow>
										))}

									<TableRow key={5}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Cash Flows from Operating Activities
											</Typography>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={
													this.state.net_cash_flows_from_operating_activities
												}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>
									<TableRow key={6}>
										<TableCell colSpan={3}>&nbsp;</TableCell>
									</TableRow>

									<TableRow key={7}>
										<TableCell>Cash Flow from Investing Activities</TableCell>
										<TableCell></TableCell>
										<TableCell align="right"></TableCell>
									</TableRow>

									<TableRow key={8}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Cash Flows from Investing Activities
											</Typography>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={0}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>

									<TableRow key={9}>
										<TableCell colSpan={3}>&nbsp;</TableCell>
									</TableRow>

									<TableRow key={10}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Cash Flow from Financing Activities
											</Typography>
										</TableCell>
										<TableCell align="right"></TableCell>
										<TableCell></TableCell>
									</TableRow>

									{this.state.rows_equity &&
										this.state.rows_equity.map((row, index) => (
											<TableRow key={index + 11}>
												<TableCell>{row.name}</TableCell>
												<TableCell align="right">
													<NumberFormat
														value={row.result}
														displayType={"text"}
														thousandSeparator="."
														decimalSeparator=","
														decimalScale={2}
														fixedDecimalScale={true}
														prefix={""}
													/>
												</TableCell>

												<TableCell></TableCell>
											</TableRow>
										))}

									<TableRow key={12}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Cash Flows from Financing Activities
											</Typography>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={
													this.state.net_cash_flows_from_financing_activities
												}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>

									<TableRow key={13}>
										<TableCell colSpan={3}>&nbsp;</TableCell>
									</TableRow>

									<TableRow key={14}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Increase/Decrease for the period
											</Typography>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={this.state.net_increase_decrease_for_the_period}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>

									<TableRow key={15}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Cash at the Beginning of the period
											</Typography>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={this.state.cash_at_the_beginning_of_the_period}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>

									<TableRow key={16}>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Cash at the End of the period
											</Typography>
										</TableCell>
										<TableCell></TableCell>
										<TableCell align="right">
											<NumberFormat
												value={this.state.cash_at_the_end_of_the_period}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(StatementOfCashFlowReport);
