import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
//import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import NumberFormat from "react-number-format";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

class standardBalanceSheetReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateTo;

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchTo: dateTo,
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			searchInclude0Balance: "0",
			rows: [],
			rowsEquity: [],
			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			netAssets: 0,
			xlsUrl: "https://fasreport.raywhite.co.id/standardbalancesheet",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Standard Balance Sheet");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateTo(this.state.searchTo);
		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			to: this.state.searchTo,
			include0balance: this.state.searchInclude0Balance,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getStandardBalanceSheet(params)
				.then((response) => {
					this.setState(
						{
							rows: response.data.Data,
							rowsEquity: response.data.Data2,
						},
						function () {
							let totalAssets = 0;
							let totalLiabilities = 0;

							if (this.state.rows != null) {
								let rows = [...this.state.rows];

								for (let i = 0; i < rows.length; i++) {
									if (rows[i].id_parent === 0) {
										if (rows[i].id_classification === 1) {
											totalAssets += rows[i].current_balance;
										} else {
											totalLiabilities += rows[i].current_balance;
										}
									}
								}
							}

							this.setState({
								netAssets: totalAssets - totalLiabilities,
							});
						}
					);
					this.props.hideLoading();
				})
				.catch((e) => {
					//console.log(e);
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchIdCompany(e) {
		this.setState({ searchIdCompany: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}
	onChangeSearchInclude0Balance(e) {
		let checked = "0";
		if (e.target.checked) {
			checked = "1";
		}
		this.setState({ searchInclude0Balance: checked });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	tableRow(row, index) {
		let i = 0;
		let prefix = "---> ";
		let newName = row.name;

		for (i = 1; i < row.depth; i++) {
			newName = prefix + newName;
		}

		return (
			<TableRow key={index}>
				<TableCell>
					{row.header ? (
						<Typography variant="subtitle2" gutterBottom component="div">
							{newName}
						</Typography>
					) : (
						<Typography variant="body2" gutterBottom component="div">
							{newName}
						</Typography>
					)}
				</TableCell>

				<TableCell align="right">
					{row.depth > 3 && (
						<NumberFormat
							value={row.current_balance}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					)}
				</TableCell>
				<TableCell align="right">
					{row.depth === 3 && (
						<NumberFormat
							value={row.current_balance}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					)}
				</TableCell>
				<TableCell align="right">
					{row.depth === 2 && (
						<NumberFormat
							value={row.current_balance}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					)}
				</TableCell>

				<TableCell align="right">
					{row.depth === 1 && (
						<NumberFormat
							value={row.current_balance}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					)}
				</TableCell>
			</TableRow>
		);
	}

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.searchIdCompany}
													onChange={(e) => this.onChangeSearchIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<TextField
										label="As Of"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={9}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													this.state.searchInclude0Balance === "1" ||
													this.state.searchInclude0Balance === 1
												}
												onChange={(e) => this.onChangeSearchInclude0Balance(e)}
											/>
										}
										label="Include 0 Balance"
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									{this.state.companies_ready &&
										this.state.companies.length > 1 && (
											<FormControl>
												<FormLabel>Consolidate</FormLabel>
												<FormGroup>
													{this.state.companies.map((option, index) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																/>
															}
															value={option.id}
															label={option.name}
														/>
													))}
												</FormGroup>
											</FormControl>
										)}
								</Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&to=" +
											this.state.searchTo +
											"&include0balance=" +
											this.state.searchInclude0Balance +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Standard Balance Sheet
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableBody>
									{this.state.rows &&
										this.state.rows.map((row, index) => (
											<>{this.tableRow(row, index)}</>
										))}

									<TableRow>
										<TableCell colSpan={5}>&nbsp;</TableCell>
									</TableRow>

									<TableRow>
										<TableCell>
											<Typography
												variant="subtitle2"
												gutterBottom
												component="div"
											>
												Net Assets
											</Typography>
										</TableCell>
										<TableCell colSpan={4} align="right">
											<NumberFormat
												value={this.state.netAssets}
												displayType={"text"}
												thousandSeparator="."
												decimalSeparator=","
												decimalScale={2}
												fixedDecimalScale={true}
												prefix={""}
											/>
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell colSpan={5}>&nbsp;</TableCell>
									</TableRow>

									{this.state.rowsEquity &&
										this.state.rowsEquity.map((row, index) => (
											<>{this.tableRow(row, index)}</>
										))}

									<TableRow>
										<TableCell colSpan={5}>&nbsp;</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateTo,
	showLoading,
	hideLoading,
})(standardBalanceSheetReport);
