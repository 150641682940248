import React, { Component } from "react";
import { connect } from "react-redux";
import { createUser } from "../../actions/user";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import UserDataService from "../../services/users.service";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import isEmail from "validator/lib/isEmail";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

class AddUser extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			email: "",
			name: "",
			password: "",
			active: true,
			id_company: 0,
			comp: "",
			usergroup: "user",

			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			emailError: false,
		};
	}

	usergroups = [
		{
			label: "User",
			value: "user",
		},
		{
			label: "Administrator",
			value: "administrator",
		},
	];

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("User");
			if (typeof this.props.match.params.id !== "undefined") {
				this.loadCurrentRow();
				this.loadSelect();
			} else {
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState(
				{
					companies_ready: true,
					companies: response.data.Row,
				} /*,
				function () {
					let email = new URLSearchParams(this.props.location.search).get(
						"email"
					);
					let name = new URLSearchParams(this.props.location.search).get(
						"name"
					);
					let id_company = new URLSearchParams(this.props.location.search).get(
						"id_company"
					);

					this.setState({
						email: email,
						name: name,
						password: email,
						id_company: parseNumber(id_company),
					});
				}*/
			);
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		UserDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						email: response.data.Row.email,
						name: response.data.Row.name,
						active: response.data.Row.active,
						id_company: response.data.Row.id_company,
						comp: response.data.Row.companies,
						usergroup: response.data.Row.usergroup,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			email: "",
			name: "",
			password: "",
			active: true,
			id_company: 0,
			comp: "",
			usergroup: "user",

			message: false,
		});
	}
	onChangeEmail(e) {
		let val = e.target.value;

		if (isEmail(val)) {
			this.setState({ emailError: false, email: val });
		} else {
			this.setState({ emailError: true, email: val });
		}
	}
	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangePassword(e) {
		this.setState({ password: e.target.value });
	}
	onChangeActive(e) {
		this.setState({ active: e.target.value });
	}
	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeUsergroup(e) {
		this.setState({ usergroup: e.target.value });
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveUser(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		if (this.state.emailError) {
			this.setState({
				message: "Email not Valid",
				error: true,
			});
			this.props.hideLoading();
		} else {
			this.props
				.createUser(
					this.state.id,
					this.state.email,
					this.state.name,
					this.state.password,
					this.state.active,
					this.state.id_company,
					this.state.comp,
					this.state.usergroup
				)
				.then((data) => {
					this.props.history.push("/user/form");
				})
				.catch((e) => {
					console.log(e.response.data.error);
					this.setState({
						message: e.response.data.error,
						error: true,
					});
					this.props.hideLoading();
				});
		}
	}

	CheckedCompanies(id) {
		const cs = this.state.comp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.comp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ comp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveUser(e)}>
							<Typography variant="h5">User Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Email"
										error={this.state.emailError}
										value={this.state.email}
										onChange={(e) => this.onChangeEmail(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Password"
										helperText="leave it blank if you don't want to change the password"
										value={this.state.password}
										onChange={(e) => this.onChangePassword(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.companies_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idCompany">Company</InputLabel>
											<Select
												labelId="idCompany"
												label="Company"
												value={this.state.id_company}
												onChange={(e) => this.onChangeIdCompany(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.companies.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<InputLabel id="usergrouplabel">User Group</InputLabel>
										<Select
											labelId="usergrouplabel"
											label="User Group"
											value={this.state.usergroup}
											onChange={(e) => this.onChangeUsergroup(e)}
										>
											{this.usergroups.map((option, index) => (
												<MenuItem key={index} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={3}>
									<FormControl>
										<FormLabel>Active</FormLabel>
										<RadioGroup row>
											<FormControlLabel
												control={<Radio />}
												label="Active"
												value={true}
												checked={
													this.state.active === "true" ||
													this.state.active === true
												}
												onChange={(e) => this.onChangeActive(e)}
											/>

											<FormControlLabel
												control={<Radio />}
												label="Inactive"
												value={false}
												checked={
													this.state.active === "false" ||
													this.state.active === false
												}
												onChange={(e) => this.onChangeActive(e)}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={12}>
									{this.state.companies_ready && (
										<FormControl>
											<FormLabel>Consolidate</FormLabel>
											<FormGroup>
												{this.state.companies.map((option, index) => (
													<FormControlLabel
														control={
															<Checkbox
																checked={this.CheckedCompanies(option.id)}
																onChange={(e) => this.onChangeCompanies(e)}
															/>
														}
														value={option.id}
														label={option.name}
													/>
												))}
											</FormGroup>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/user/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createUser,
	showLoading,
	hideLoading,
	setModule,
})(AddUser);
