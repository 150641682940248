import http_fas from "../http-fas";

class JournalDataService {
	getAll(params) {
		return http_fas.get("/journals", { params });
	}

	get(id) {
		return http_fas.get(`/journals/${id}`);
	}

	create(data) {
		return http_fas.post("/savejournal", data);
	}

	delete(id) {
		return http_fas.delete(`/removejournal/${id}`);
	}

	code(id) {
		return http_fas.get(`/journalcode/${id}`);
	}

	getAccounts() {
		return http_fas.get(`master/account?tipe=journal`);
	}

	getJobs() {
		return http_fas.get(`master/job`);
	}

	getTaxes() {
		return http_fas.get(`master/tax`);
	}
}

export default new JournalDataService();
