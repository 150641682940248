import http_fas from "../http-fas";

class ReceiveMoneyDataService {
	getAll(params) {
		return http_fas.get("/receivemoney", { params });
	}

	get(id) {
		return http_fas.get(`/receivemoney/${id}`);
	}

	create(data) {
		return http_fas.post("/savereceivemoney", data);
	}

	delete(id) {
		return http_fas.delete(`/removereceivemoney/${id}`);
	}

	code(id) {
		return http_fas.get(`/receivemoneycode/${id}`);
	}

	getPaymentMethod() {
		return http_fas.get(`master/payment_method`);
	}
}

export default new ReceiveMoneyDataService();
