import React, { Component } from "react";
import { connect } from "react-redux";
import { createReceivePayment } from "../../actions/receivePayment";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import ReceivePaymentDataService from "../../services/receivePayment.service";
import ReceiveMoneyDataService from "../../services/receiveMoney.service";
import TransferMoneyDataService from "../../services/transfer_money.service";
import InvoiceDataService from "../../services/invoice.service";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";
import Divider from "@mui/material/Divider";
import NumberFormat from "react-number-format";
import { parseNumber } from "../../helpers/common";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import FormCard from "../card/popup";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue } from "../../helpers/common";

class AddReceivePayment extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			id_source: 0,
			id_card: 0,
			code: "",
			date: format(new Date(), "yyyy-MM-dd"),
			id_payment_method: 0,
			note: "",
			amount: 0,
			from: 0,

			error: false,
			message: false,

			companies_ready: false,
			accounts_ready: false,
			paymentMethods_ready: false,
			cards_ready: false,

			companies: [],
			accounts: [],
			paymentMethods: [],
			cards: [],

			formCard: false,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Receive Payment");
			if (typeof this.props.match.params.id !== "undefined") {
				let id = parseNumber(this.props.match.params.id);
				let id_source = parseNumber(this.props.match.params.id_source);

				if (id > 0) {
					this.loadCurrentRow();
				}

				if (id_source > 0) {
					ReceivePaymentDataService.code(this.props.auth.idCompany).then(
						(response) => {
							this.setState(
								{ code: response.data, id_source: id_source },
								function () {
									this.loadInvoiceRow();
								}
							);
						}
					);
				}

				this.loadSelect();
			} else {
				ReceivePaymentDataService.code(this.props.auth.idCompany).then(
					(response) => {
						this.setState({ code: response.data });
					}
				);

				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadCards() {
		CardDataService.getCards().then((response) => {
			this.setState(
				{
					cards_ready: true,
					cards: response.data.Row,
				},
				function () {
					this.setState({
						formCard: false,
					});
				}
			);
		});
	}

	loadSelect() {
		this.loadCards();

		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		TransferMoneyDataService.getAccounts().then((response) => {
			this.setState({
				accounts_ready: true,
				accounts: response.data.Row,
			});
		});
		ReceiveMoneyDataService.getPaymentMethod().then((response) => {
			this.setState({
				paymentMethods_ready: true,
				paymentMethods: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		ReceivePaymentDataService.get(this.props.match.params.id)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						id_source: response.data.Row.id_source,
						id_card: response.data.Row.id_card,
						code: response.data.Row.code,
						date: response.data.Row.date,
						id_payment_method: response.data.Row.id_payment_method,
						note: response.data.Row.note,
						amount: response.data.Row.amount,
						from: response.data.Row.from,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	loadInvoiceRow() {
		this.props.showLoading();

		InvoiceDataService.get(this.props.match.params.id_source)
			.then((response) => {
				this.setState(
					{
						id: null,
						id_company: this.props.auth.idCompany,
						id_card: response.data.Row.id_card,
						id_payment_method: response.data.Row.id_payment_method,
						amount: response.data.Row.outstanding,
					},
					function () {
						TransferMoneyDataService.getCustomerReceipts().then((res) => {
							this.setState({ from: res.data });
							this.props.hideLoading();
						});
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			id_company: this.props.auth.idCompany,
			id_source: 0,
			id_card: 0,
			code: "",
			date: format(new Date(), "yyyy-MM-dd"),
			id_payment_method: 0,
			note: "",
			amount: 0,
			from: 0,

			message: false,
		});
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeIdCard(e) {
		this.setState({ id_card: e.target.value });
	}
	onChangeCode(e) {
		this.setState({ code: e.target.value });
	}
	onChangeDate(e) {
		this.setState({ date: e.target.value });
	}
	onChangeIdPaymentMethod(e) {
		this.setState({ id_payment_method: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}
	onChangeAmount(e) {
		this.setState({ amount: e.target.value });
	}
	onEnterAmount(e) {
		if (e.key === "Enter") {
			this.setState({ amount: e.target.value });
		}
	}
	onChangeFrom(e, val) {
		if (val !== null) {
			this.setState({ from: val.id });
		}
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveReceivePayment(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createReceivePayment(
				this.state.id,
				this.state.id_company,
				this.state.id_source,
				this.state.id_card,
				this.state.code,
				this.state.date,
				this.state.id_payment_method,
				this.state.note,
				parseNumber(this.state.amount),
				this.state.from
			)
			.then((data) => {
				this.props.history.push("/invoice/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.error,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	addCard = () => {
		this.setState({ formCard: true });
	};

	formCardClose = () => {
		this.setState({ formCard: false });
	};

	setNewCard(id, name) {
		this.loadCards();
		this.setState({
			id_card: id,
		});
	}

	showError(msg) {
		this.setState({
			message: msg,
			error: true,
		});
	}

	render() {
		return (
			<Box component="form" onSubmit={(e) => this.saveReceivePayment(e)}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
							<Typography variant="h5">Receive Payment Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>

									<FormCard
										show={this.state.formCard}
										cardType=""
										formCardClose={(e) => this.formCardClose(e)}
										setNewCard={(id, name) => this.setNewCard(id, name)}
										showError={(e) => this.showError(e)}
									/>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									{this.state.accounts_ready && (
										<Autocomplete
											options={this.state.accounts}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) => this.onChangeFrom(e, val)}
											value={AcIndexByValue(
												this.state.accounts,
												this.state.from
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Deposit to Account"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									<Grid container spacing={0}>
										<Grid item xs={1}>
											<IconButton
												onClick={this.addCard}
												color="primary"
												aria-label="add card"
											>
												<AddBoxIcon />
											</IconButton>
										</Grid>
										<Grid item xs={11}>
											{this.state.cards_ready && (
												<FormControl
													fullWidth={true}
													variant="standard"
													sx={{ minWidth: 100 }}
												>
													<InputLabel id="idCardsLabel">Card</InputLabel>
													<Select
														labelId="idCardsLabel"
														label="Card"
														value={this.state.id_card}
														onChange={(e) => this.onChangeIdCard(e)}
													>
														<MenuItem value="0">
															<em>None</em>
														</MenuItem>
														{this.state.cards.map((option, index) => (
															<MenuItem key={index} value={option.id}>
																{option.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											)}
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Code"
										type="text"
										value={this.state.code}
										onChange={(e) => this.onChangeCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Date"
										type="date"
										value={this.state.date}
										onChange={(e) => this.onChangeDate(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<NumberFormat
											customInput={TextField}
											label="Amount"
											value={this.state.amount}
											fullWidth
											variant="standard"
											thousandSeparator="."
											decimalSeparator=","
											decimalScale={2}
											fixedDecimalScale={true}
											autoComplete="off"
											onBlur={(e) => this.onChangeAmount(e)}
											onKeyDown={(e) => this.onEnterAmount(e)}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.paymentMethods_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idPaymentMethodsLabel">
												Payment Method
											</InputLabel>
											<Select
												labelId="idPaymentMethodsLabel"
												label="Payment Method"
												value={this.state.id_payment_method}
												onChange={(e) => this.onChangeIdPaymentMethod(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.paymentMethods.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Note"
										fullWidth
										multiline
										rows={2}
										value={this.state.note}
										onChange={(e) => this.onChangeNote(e)}
									/>
								</Grid>

								<Grid item xs={12} sm={12}>
									<Divider />
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/receivepayment/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createReceivePayment,
	showLoading,
	hideLoading,
	setModule,
})(AddReceivePayment);
