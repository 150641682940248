import http from "../http-common";
import httpm from "../http-multipart";

//const http = req.http;
//const httpMultipart = req.httpMultipart;

class TutorialDataService {
    getAll(params) {
        return http.get("/tutorials", { params });
    }

    get(id) {
        return http.get(`/tutorials/${id}`);
    }

    create(data) {

        return httpm.post("/tutorials", data);
    }

    update(id, data) {
        return http.put(`/tutorials/${id}`, data);
    }

    delete(id) {
        return http.delete(`/tutorials/${id}`);
    }
}

export default new TutorialDataService();