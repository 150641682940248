import http_fas from "../http-fas";

class BillDataService {
	getAll(params) {
		return http_fas.get("/bill", { params });
	}

	get(id) {
		return http_fas.get(`/bill/${id}`);
	}

	create(data) {
		return http_fas.post("/savebill", data);
	}

	delete(id) {
		return http_fas.delete(`/removebill/${id}`);
	}

	code(id) {
		return http_fas.get(`/purchaseordercode/${id}`);
	}
}

export default new BillDataService();
