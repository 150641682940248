import http_fas from "../http-fas";

class InvoiceDataService {
	getAll(params) {
		return http_fas.get("/invoice", { params });
	}

	get(id) {
		return http_fas.get(`/invoice/${id}`);
	}

	create(data) {
		return http_fas.post("/saveinvoice", data);
	}

	delete(id) {
		return http_fas.delete(`/removeinvoice/${id}`);
	}

	code(id) {
		return http_fas.get(`/ordercode/${id}`);
	}
}

export default new InvoiceDataService();
