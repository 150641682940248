import http_fas from "../http-fas";

class OrderDataService {
	getAll(params) {
		return http_fas.get("/order", { params });
	}

	get(id) {
		return http_fas.get(`/order/${id}`);
	}

	create(data) {
		return http_fas.post("/saveorder", data);
	}

	delete(id) {
		return http_fas.delete(`/removeorder/${id}`);
	}

	code(id) {
		return http_fas.get(`/ordercode/${id}`);
	}
}

export default new OrderDataService();
