import http_fas from "../http-fas";

class UserDataService {
	getAll(params) {
		return http_fas.get("/users", { params });
	}

	get(id) {
		return http_fas.get(`/users/${id}`);
	}

	create(data) {
		return http_fas.post("/saveuser", data);
	}

	delete(id) {
		return http_fas.delete(`/removeuser/${id}`);
	}

	updateProfile(data) {
		return http_fas.post("/updateprofile", data);
	}
}

export default new UserDataService();
