import React, { Component } from "react";
import { connect } from "react-redux";
import { setModule, setDateFrom, setDateTo } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";

import ReportDataService from "../../services/reports.service";
import CardDataService from "../../services/card.service";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { format } from "date-fns";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { parseDate } from "../../helpers/date";
import NumberFormat from "react-number-format";
import { linkType } from "../../helpers/common";

import Link from "@mui/material/Link";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

class TransactionJournalReport extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		let dateFrom, dateTo;

		if (this.props.common.dateFrom) {
			dateFrom = this.props.common.dateFrom;
		} else {
			dateFrom = format(new Date(), "yyyy-MM-01");
		}

		if (this.props.common.dateTo) {
			dateTo = this.props.common.dateTo;
		} else {
			dateTo = format(new Date(), "yyyy-MM-dd");
		}

		this.state = {
			searchFrom: dateFrom,
			searchTo: dateTo,
			searchIdCompany: this.props.auth.idCompany,
			searchComp: "",
			rows: [],
			error: false,
			message: false,

			companies_ready: false,
			companies: [],
			xlsUrl: "https://fasreport.raywhite.co.id/transactionjournal",
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Transaction Journal");

			this.getRows();
			this.loadSelect();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getRows = () => {
		this.props.showLoading();
		this.props.setDateFrom(this.state.searchFrom);
		this.props.setDateTo(this.state.searchTo);
		var params = {
			id_company: this.state.searchIdCompany,
			companies: this.state.searchComp,
			from: this.state.searchFrom,
			to: this.state.searchTo,
		};

		this.setState({ message: false }, function () {
			ReportDataService.getTransactionJournal(params)
				.then((response) => {
					this.setState({
						rows: response.data.Data,
					});
					this.props.hideLoading();
				})
				.catch((e) => {
					this.setState({
						error: true,
						message: e.response.data.error,
					});
					this.props.hideLoading();
				});
		});
	};

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
	}

	onChangeSearchIdCompany(e) {
		this.setState({ searchIdCompany: e.target.value });
	}
	onChangeSearchFrom(e) {
		this.setState({ searchFrom: e.target.value });
	}
	onChangeSearchTo(e) {
		this.setState({ searchTo: e.target.value });
	}

	searchData = (e) => {
		e.preventDefault();
		this.getRows();
	};

	handleClose = (e) => {
		this.setState({
			error: false,
		});
	};

	tableChild(rows) {
		return rows.map((e, i) => (
			<TableRow>
				<TableCell sx={{ borderBottom: "none" }} colSpan={2}></TableCell>
				<TableCell sx={{ borderBottom: "none" }}>{e.code}</TableCell>
				<TableCell sx={{ borderBottom: "none" }}>
					{e.account_code} {e.account_name}
				</TableCell>
				<TableCell align="right" sx={{ borderBottom: "none" }}>
					{e.debit !== 0 && (
						<NumberFormat
							value={e.debit}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					)}
				</TableCell>
				<TableCell align="right" sx={{ borderBottom: "none" }}>
					{e.credit !== 0 && (
						<NumberFormat
							value={e.credit}
							displayType={"text"}
							thousandSeparator="."
							decimalSeparator=","
							decimalScale={2}
							fixedDecimalScale={true}
							prefix={""}
						/>
					)}
				</TableCell>
				<TableCell sx={{ borderBottom: "none" }}>{e.job}</TableCell>
			</TableRow>
		));
	}

	CheckedCompanies(id) {
		const cs = this.state.searchComp.split(",");
		return cs.includes(id.toString());
	}

	onChangeCompanies(e) {
		let cs = this.state.searchComp.split(",");
		let val = e.target.value.toString();

		if (e.target.checked) {
			if (!cs.includes(val)) {
				cs.push(val);
			}
		} else {
			let i = cs.indexOf(val);
			if (i !== -1) {
				cs.splice(i, 1);
			}
		}

		this.setState({ searchComp: cs.join(",").replace(/^,/, "") });
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.searchData(e)}>
							<Typography variant="h5">Search Form</Typography>

							<Grid container spacing={3}>
								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.searchIdCompany}
													onChange={(e) => this.onChangeSearchIdCompany(e)}
												>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={3}>
									<TextField
										label="From"
										type="date"
										value={this.state.searchFrom}
										onChange={(e) => this.onChangeSearchFrom(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="To"
										type="date"
										value={this.state.searchTo}
										onChange={(e) => this.onChangeSearchTo(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}></Grid>

								<Grid item xs={12} sm={12}>
									{this.state.companies_ready &&
										this.state.companies.length > 1 && (
											<FormControl>
												<FormLabel>Consolidate</FormLabel>
												<FormGroup>
													{this.state.companies.map((option, index) => (
														<FormControlLabel
															control={
																<Checkbox
																	checked={this.CheckedCompanies(option.id)}
																	onChange={(e) => this.onChangeCompanies(e)}
																/>
															}
															value={option.id}
															label={option.name}
														/>
													))}
												</FormGroup>
											</FormControl>
										)}
								</Grid>

								<Grid item xs={6} sm={6}>
									<Button
										type="submit"
										variant="contained"
										startIcon={<SearchIcon />}
									>
										Search
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} container justifyContent="flex-end">
									<Link
										href={
											this.state.xlsUrl +
											"?id_company=" +
											this.state.searchIdCompany +
											"&companies=" +
											this.state.searchComp +
											"&from=" +
											this.state.searchFrom +
											"&to=" +
											this.state.searchTo +
											"&token=" +
											this.props.auth.user.token
										}
										underline="always"
									>
										Export
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Typography variant="h4" gutterBottom component="div">
							Transaction Journal
						</Typography>

						<TableContainer component={Paper}>
							<Table sx={{}} size="small">
								<TableHead>
									<TableRow>
										<TableCell width={5}>No.</TableCell>
										<TableCell align="center">Src</TableCell>
										<TableCell align="center">Date</TableCell>
										<TableCell align="center">Account</TableCell>
										<TableCell align="center">Debit</TableCell>
										<TableCell align="center">Credit</TableCell>
										<TableCell align="center">Job</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.rows &&
										this.state.rows.map((row, index) => (
											<>
												<TableRow key={index}>
													<TableCell
														sx={{
															borderBottom: "none",
															borderTop: 1,
															borderColor: "grey.300",
														}}
														align="right"
													>
														{1 + index}
													</TableCell>
													<TableCell
														sx={{
															borderBottom: "none",
															borderTop: 1,
															borderColor: "grey.300",
														}}
													>
														{linkType(row)}
													</TableCell>
													<TableCell
														colSpan={5}
														sx={{
															borderBottom: "none",
															borderTop: 1,
															borderColor: "grey.300",
														}}
													>
														{parseDate(row.date)} {row.card} {row.note}
													</TableCell>
												</TableRow>

												{this.tableChild(row.Detail)}
											</>
										))}
								</TableBody>
							</Table>
						</TableContainer>

						<Dialog
							open={this.state.error}
							onClose={this.handleClose}
							aria-labelledby="error-dialog-title"
							aria-describedby="error-dialog-description"
						>
							<DialogTitle id="error-dialog-title">Failed</DialogTitle>
							<DialogContent>
								<DialogContentText id="error-dialog-description">
									{this.state.message}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.handleClose} autoFocus>
									Close
								</Button>
							</DialogActions>
						</Dialog>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		common: state.common,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	setModule,
	setDateFrom,
	setDateTo,
	showLoading,
	hideLoading,
})(TransactionJournalReport);
