import http_fas from "../http-fas";

class CompanyDataService {
	getAll(params) {
		return http_fas.get("/companies", { params });
	}

	get(id) {
		return http_fas.get(`/companies/${id}`);
	}

	create(data) {
		return http_fas.post("/savecompany", data);
	}

	delete(id) {
		return http_fas.delete(`/removecompany/${id}`);
	}
}

export default new CompanyDataService();
