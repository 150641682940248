import React, { Component } from "react";
import { connect } from "react-redux";
import { createAccount } from "../../actions/account";
import { setModule } from "../../actions/common";
import { showLoading, hideLoading } from "../../actions/loading";
import AccountDataService from "../../services/account.service";
import CardDataService from "../../services/card.service";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NumberFormat from "react-number-format";
import { parseNumber } from "../../helpers/common";
import Autocomplete from "@mui/material/Autocomplete";
import { AcIndexByValue } from "../../helpers/common";

class AddAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			id: null,
			id_company: this.props.auth.idCompany,
			id_parent: 0,
			id_type: 1,
			header: false,
			code: "",
			name: "",
			note: "",
			id_tax: 0,
			id_cash_flow: 1,
			bank_account_number: "",
			bank_account_name: "",
			opening_balance: 0,

			error: false,
			message: false,

			companies_ready: false,
			parents_ready: false,
			types_ready: false,
			taxes_ready: false,
			cashflows_ready: false,

			companies: [],
			parents: [],
			types: [],
			taxes: [],
			cashflows: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			this.props.setModule("Account");
			if (typeof this.props.match.params.id !== "undefined") {
				this.loadCurrentRow();
				this.loadSelect();
			} else {
				this.loadSelect();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate() {
		if (typeof this.props.match.params.id === "undefined") {
			if (this.state.id !== null) {
				this.newData();
			}
		}
	}

	loadSelect() {
		CardDataService.getCompanies().then((response) => {
			this.setState({
				companies_ready: true,
				companies: response.data.Row,
			});
		});
		AccountDataService.getParent().then((response) => {
			this.setState({
				parents_ready: true,
				parents: response.data.Row,
			});
		});
		AccountDataService.getType().then((response) => {
			this.setState({
				types_ready: true,
				types: response.data.Row,
			});
		});
		AccountDataService.getTaxes().then((response) => {
			this.setState({
				taxes_ready: true,
				taxes: response.data.Row,
			});
		});
		AccountDataService.getCashFlow().then((response) => {
			this.setState({
				cashflows_ready: true,
				cashflows: response.data.Row,
			});
		});
	}

	loadCurrentRow() {
		this.props.showLoading();

		var params = {
			id_period: new URLSearchParams(this.props.location.search).get(
				"id_period"
			),
		};

		//var ob = new URLSearchParams(this.props.location.search).get("ob");

		AccountDataService.get(this.props.match.params.id, params)
			.then((response) => {
				this.setState(
					{
						id: response.data.Row.id,
						id_company: response.data.Row.id_company,
						id_parent: response.data.Row.id_parent,
						id_type: response.data.Row.id_type,
						header: response.data.Row.header,
						code: response.data.Row.code,
						name: response.data.Row.name,
						note: response.data.Row.note,
						id_tax: response.data.Row.id_tax,
						id_cash_flow: response.data.Row.id_cash_flow,
						bank_account_number: response.data.Row.bank_account_number,
						bank_account_name: response.data.Row.bank_account_name,
						opening_balance: response.data.Row.opening_balance,
						//opening_balance: ob,
					},
					function () {
						this.props.hideLoading();
					}
				);
			})
			.catch((e) => {
				this.setState({ message: e.response.data.error });
			});
	}

	newData() {
		this.setState({
			id: null,
			id_company: this.props.auth.idCompany,
			id_parent: 0,
			id_type: 1,
			header: false,
			code: "",
			name: "",
			note: "",
			id_tax: 0,
			id_cash_flow: 1,
			bank_account_number: "",
			bank_account_name: "",
			opening_balance: 0,

			message: false,
		});
	}

	onChangeIdCompany(e) {
		this.setState({ id_company: e.target.value });
	}
	onChangeIdParent(e, val) {
		if (val !== null) {
			this.setState({ id_parent: val.id });
		}
	}
	onChangeIdType(e) {
		this.setState({ id_type: e.target.value });
	}
	onChangeIdCashFlow(e) {
		this.setState({ id_cash_flow: e.target.value });
	}
	onChangeIdTax(e) {
		this.setState({ id_tax: e.target.value });
	}
	onChangeHeader(e) {
		this.setState({ header: e.target.value });
	}
	onChangeCode(e) {
		this.setState({ code: e.target.value });
	}
	onChangeName(e) {
		this.setState({ name: e.target.value });
	}
	onChangeNote(e) {
		this.setState({ note: e.target.value });
	}
	onChangeBankAccountNumber(e) {
		this.setState({ bank_account_number: e.target.value });
	}
	onChangeBankAccountName(e) {
		this.setState({ bank_account_name: e.target.value });
	}
	onChangeOpeningBalance(e) {
		this.setState({ opening_balance: e.target.value });
	}
	onEnterOpeningBalance(e) {
		if (e.key === "Enter") {
			this.setState({ opening_balance: e.target.value });
		}
	}

	alertClose = () => {
		this.setState({ error: false });
	};

	saveAccount(e) {
		e.preventDefault();
		this.props.showLoading();

		this.setState({
			message: false,
		});

		this.props
			.createAccount(
				this.state.id,
				this.state.id_company,
				this.state.id_parent,
				this.state.id_type,
				this.state.header,
				this.state.code,
				this.state.name,
				this.state.note,
				this.state.id_tax,
				this.state.id_cash_flow,
				this.state.bank_account_number,
				this.state.bank_account_name,
				parseNumber(this.state.opening_balance),
				parseNumber(
					new URLSearchParams(this.props.location.search).get("id_period")
				)
			)
			.then((data) => {
				this.props.history.push("/account/form");
			})
			.catch((e) => {
				this.setState({
					message: e.response.data.message,
					error: true,
				});
				this.props.hideLoading();
			});
	}

	render() {
		return (
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
						<Box component="form" onSubmit={(e) => this.saveAccount(e)}>
							<Typography variant="h5">Account Form</Typography>

							<Grid container spacing={3}>
								<Grid item xs={12} sm={12}>
									<Dialog
										open={this.state.error}
										onClose={this.alertClose}
										aria-labelledby="error-dialog-title"
										aria-describedby="error-dialog-description"
									>
										<DialogTitle id="error-dialog-title">Failed</DialogTitle>
										<DialogContent>
											<DialogContentText id="error-dialog-description">
												{this.state.message}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={this.alertClose} autoFocus>
												Close
											</Button>
										</DialogActions>
									</Dialog>
								</Grid>

								{this.props.auth.user.usergroup === "administrator" && (
									<Grid item xs={12} sm={12}>
										{this.state.companies_ready && (
											<FormControl
												fullWidth={true}
												variant="standard"
												sx={{ minWidth: 100 }}
											>
												<InputLabel id="idCompany">Company</InputLabel>
												<Select
													labelId="idCompany"
													label="Company"
													value={this.state.id_company}
													onChange={(e) => this.onChangeIdCompany(e)}
												>
													<MenuItem value="0">
														<em>None</em>
													</MenuItem>
													{this.state.companies.map((option, index) => (
														<MenuItem key={index} value={option.id}>
															{option.name}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Grid>
								)}

								<Grid item xs={12} sm={6}>
									<FormControl>
										<FormLabel>Header</FormLabel>
										<RadioGroup row>
											<FormControlLabel
												control={<Radio />}
												label="Yes"
												value={true}
												checked={
													this.state.header === "true" ||
													this.state.header === true
												}
												onChange={(e) => this.onChangeHeader(e)}
											/>

											<FormControlLabel
												control={<Radio />}
												label="No"
												value={false}
												checked={
													this.state.header === "false" ||
													this.state.header === false
												}
												onChange={(e) => this.onChangeHeader(e)}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.types_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="idType">Type</InputLabel>
											<Select
												labelId="idType"
												label="Type"
												value={this.state.id_type}
												onChange={(e) => this.onChangeIdType(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.types.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.groups} - {option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									{this.state.parents_ready && (
										<Autocomplete
											options={this.state.parents}
											getOptionLabel={(option) => option.name}
											fullWidth={true}
											onChange={(e, val) => this.onChangeIdParent(e, val)}
											value={AcIndexByValue(
												this.state.parents,
												this.state.id_parent
											)}
											sx={{ minWidth: 100 }}
											renderInput={(params) => (
												<TextField
													variant="standard"
													{...params}
													label="Parent"
												/>
											)}
										/>
									)}
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Code"
										value={this.state.code}
										onChange={(e) => this.onChangeCode(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										required
										label="Name"
										value={this.state.name}
										onChange={(e) => this.onChangeName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									<TextField
										label="Bank Account Number"
										value={this.state.bank_account_number}
										onChange={(e) => this.onChangeBankAccountNumber(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} sm={3}>
									<TextField
										label="Bank Account Name"
										value={this.state.bank_account_name}
										onChange={(e) => this.onChangeBankAccountName(e)}
										fullWidth
										variant="standard"
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<div>{parseNumber(this.state.opening_balance)}</div>
									<FormControl
										fullWidth={true}
										variant="standard"
										sx={{ minWidth: 100 }}
									>
										<NumberFormat
											customInput={TextField}
											label="Opening Balance"
											value={this.state.opening_balance}
											fullWidth
											variant="standard"
											thousandSeparator="."
											decimalSeparator=","
											decimalScale={2}
											fixedDecimalScale={true}
											autoComplete="off"
											onBlur={(e) => this.onChangeOpeningBalance(e)}
											onKeyDown={(e) => this.onEnterOpeningBalance(e)}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										label="Note"
										fullWidth
										multiline
										rows={2}
										value={this.state.note}
										onChange={(e) => this.onChangeNote(e)}
									/>
								</Grid>

								<Grid item xs={12} sm={3}>
									{this.state.taxes_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="id_tax">Tax</InputLabel>
											<Select
												labelId="id_tax"
												label="Tax"
												value={this.state.id_tax}
												onChange={(e) => this.onChangeIdTax(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.taxes.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={3}>
									{this.state.cashflows_ready && (
										<FormControl
											fullWidth={true}
											variant="standard"
											sx={{ minWidth: 100 }}
										>
											<InputLabel id="id_tax">Cash Flow</InputLabel>
											<Select
												labelId="id_cash_flow"
												label="Cash Flow"
												value={this.state.id_cash_flow}
												onChange={(e) => this.onChangeIdCashFlow(e)}
											>
												<MenuItem value="0">
													<em>None</em>
												</MenuItem>
												{this.state.cashflows.map((option, index) => (
													<MenuItem key={index} value={option.id}>
														{option.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								</Grid>

								<Grid item xs={12} sm={12}>
									<Button
										variant="contained"
										component={Link}
										to="/account/form"
										startIcon={<ArrowBackIcon />}
										sx={{ mr: 1 }}
									>
										Cancel
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={this.props.loading.loading}
										startIcon={<SaveIcon />}
									>
										{this.props.loading.loading && (
											<span>
												<span className="spinner-border spinner-border-sm"></span>
												&nbsp;
											</span>
										)}
										<span>
											{" "}
											{this.props.loading.loading ? "Please Wait" : "Save"}
										</span>
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		loading: state.loading,
	};
};

export default connect(mapStateToProps, {
	createAccount,
	showLoading,
	hideLoading,
	setModule,
})(AddAccount);
