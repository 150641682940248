import {
	CREATE_COMPANY,
	RETRIEVE_COMPANY,
	DELETE_COMPANY,
} from "../actions/types";

const initialState = {};

const companyReducer = function (company = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CREATE_COMPANY:
			return payload;

		case RETRIEVE_COMPANY:
			return payload;

		case DELETE_COMPANY:
			return payload;

		default:
			return company;
	}
};

export default companyReducer;
