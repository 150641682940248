import http_fas from "../http-fas";

class SpendMoneyDataService {
	getAll(params) {
		return http_fas.get("/spendmoney", { params });
	}

	getAllBankRegister(params) {
		return http_fas.get("/bankregister", { params });
	}

	getAllSalesRegister(params) {
		return http_fas.get("/salesregister", { params });
	}

	getAllPurchasesRegister(params) {
		return http_fas.get("/purchasesregister", { params });
	}

	get(id) {
		return http_fas.get(`/spendmoney/${id}`);
	}

	create(data) {
		return http_fas.post("/savespendmoney", data);
	}

	delete(id) {
		return http_fas.delete(`/removespendmoney/${id}`);
	}

	code(id) {
		return http_fas.get(`/spendmoneycode/${id}`);
	}
}

export default new SpendMoneyDataService();
