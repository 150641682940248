import http_fas from "../http-fas";

class ReceivePaymentDataService {
	getAll(params) {
		return http_fas.get("/receivepayment", { params });
	}

	get(id) {
		return http_fas.get(`/receivepayment/${id}`);
	}

	create(data) {
		return http_fas.post("/savereceivepayment", data);
	}

	delete(id) {
		return http_fas.delete(`/removereceivepayment/${id}`);
	}

	code(id) {
		return http_fas.get(`/receivemoneycode/${id}`);
	}
}

export default new ReceivePaymentDataService();
