import { Link } from "react-router-dom";

export function parseNumber(s) {
	if (typeof s === "undefined") {
		return 0;
	}

	let ns = s;
	if (s > 0 || s === 0 || s < 0) {
	} else {
		if (s == null) {
			return 0;
		}

		ns = s.replaceAll(".", "").replaceAll(",", ".");
		//ns = s.replaceAll(",", ".");
	}

	if (isNaN(parseFloat(ns))) {
		return 0;
	} else {
		return parseFloat(ns);
	}
}

export function linkType(row) {
	let segment = "";

	switch (row.tipe) {
		case "journal":
			segment = "editjournal";
			break;
		case "transfer_money":
			segment = "edittransfermoney";
			break;
		case "spend_money":
			segment = "editspendmoney";
			break;
		case "receive_money":
			segment = "editreceivemoney";
			break;
		case "invoice":
			segment = "editinvoice";
			break;
		case "receive_payment":
			segment = "editreceivepayment";
			break;
		case "bill":
			segment = "editbill";
			break;
		case "pay_bill":
			segment = "editpaybill";
			break;
		default:
			segment = row.tipe;
			break;
	}

	return <Link to={segment + "/" + row.id_journal}>{row.journal_type}</Link>;
}

export function AcIndexByValue(items, id) {
	let i = items.map((item) => item.id).indexOf(id);

	if (i >= 0) {
		return items[i];
	} else {
		return null;
	}
}
