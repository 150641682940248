import http_fas from "../http-fas";

class ToDoListDataService {
	getAll(params) {
		return http_fas.get("/todolist", { params });
	}

	delete(source, id) {
		return http_fas.delete(`/removetodolist/${source}/${id}`);
	}
}

export default new ToDoListDataService();
